.contact-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 155px 0 35px 0;
  background-image: url(../../assets/lgpaper.webp);
  background-repeat: no-repeat;
  background-size: 115% 110%;
  background-position: 25% 20%;
  color: white;
}

@media (max-width: 425px) {
  .contact-page {
    background-size: 600%;
    background-repeat: no-repeat;
    background-position: top right;
  }
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .contact-page {
    min-height: 1200px;
  }
}

.contact-page input {
  outline: none;
}

.contact-page .contact-right {
  width: 52%;
}

@media (max-width: 1367px) {
  .contact-page .contact-right {
    display: none;
  }
}

.contact-page #contact {
  padding: 50px;
  width: 50%;
  margin: 50px 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-radius: 12px;
}

.contact-page #contact h3 {
  text-align: center;
  font-size: 33px;
}

.contact-page #contact hr {
  margin: 5px auto 15px;
  width: 50%;
}

@media (max-width: 1367px) {
  .contact-page #contact {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .contact-page #contact {
    margin: auto 40px;
  }
}

.contact-page #contact .form-contact-content {
  width: 100%;
}

.contact-page #contact .form-contact-content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact-page #contact .form-contact-content form .identifiant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 748px) {
  .contact-page #contact .form-contact-content form .identifiant {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.contact-page #contact .form-contact-content form .identifiant .form-text {
  padding: 5px;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 748px) {
  .contact-page #contact .form-contact-content form .identifiant .form-text {
    width: 100%;
  }
}

.contact-page #contact .form-contact-content form .coordonees {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 748px) {
  .contact-page #contact .form-contact-content form .coordonees {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.contact-page #contact .form-contact-content form .coordonees .form-text {
  padding: 5px;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 748px) {
  .contact-page #contact .form-contact-content form .coordonees .form-text {
    width: 100%;
  }
}

.contact-page #contact .form-contact-content form .message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 25px;
}

.contact-page #contact .form-contact-content form .message .form-text {
  padding: 5px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact-page #contact .form-contact-content form label {
  font-weight: 600;
  font-size: 22px;
  margin: 5px 0;
}

@media (max-width: 748px) {
  .contact-page #contact .form-contact-content form label {
    font-size: 22px;
  }
}

.contact-page #contact .form-contact-content form input {
  padding: 10px;
  font-size: 20px;
}

.contact-page #contact .form-contact-content form textarea {
  font-size: 20px;
  padding: 10px;
  outline: none;
}

.contact-page #contact .form-contact-content form .g-recaptcha {
  margin-left: 5px;
  -ms-flex-item-align: right;
      -ms-grid-row-align: right;
      align-self: right;
}

.contact-page #contact .form-contact-content form button {
  margin-top: 25px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  background-color: #09A4E7;
  padding: 5px;
  color: white;
  font-size: 22px;
  border-radius: 5px;
  width: 100%;
  border: none;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .contact-page #contact .form-contact-content form button {
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 150px;
  }
}
