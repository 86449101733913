footer {
  height: 218px;
  font-family: 'Montserrat';
  padding: 20px;
  background-image: url(../../assets/paper.webp);
  background-position: bottom;
  background-size: 350%;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
}

footer a {
  color: white;
}

footer .title {
  font-size: 20px;
}

footer h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  font-weight: 300;
}

footer h3 img {
  margin: 5px;
  padding-top: 5px;
  height: 40px;
}
