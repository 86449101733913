footer {
    height: 218px;
    font-family: 'Montserrat';
    padding: 20px;
    background-image: url(../../assets/paper.webp);
    background-position: bottom;
    background-size: 350%;
    color: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-size: 15px;

    a {
        color: white;
    }

    .title {
        font-size: 20px;
    }

    h3 {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 300;

        img {
            margin: 5px;
            padding-top: 5px;
            height: 40px;
        }
    }

}
