@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

header {
    position: fixed;
    background: transparent;
    width: 100%;
    padding: 25px;
    z-index: 5;
    transition: 0.5s ease;

    &.active {
        background: white;
        padding: 10px;
        transition: .2s ease-in;

    }

    &.active nav span i {
        filter: invert(50%);
    }

    &.active nav a img {
        height: auto;
        max-width: 500px;
        padding: 5px;
        transition: .2s ease-in;

        @media (max-width: 920px) {
            height: auto;
            width: 200px;
            padding: 5px;
        }
    }

    &.active nav {
        height: 80px;

        @media (max-width: 990px) {
            height: 40px;
        }
    }

    @media (max-width: 990px) {
        padding: 5px 20px;
    }

    nav {
        height: 100px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;


        @media (max-width: 990px) {
            height: 65px;
            flex-direction: column;
            align-items: flex-start;
        }


        span {
            position: absolute;
            display: block;
            right: 30px;
            top: 20px;
            height: 20px;
            width: 20px;
            color: white;

            &.active i {

                content: url(../../assets/cross.svg);
                height: 100%;
                width: 100%;
            }

            @media (min-width: 990px) {
                display: none;
            }

            i {
                content: url(../../assets/menu.svg);
                height: 100%;
                width: 100%;

            }
        }



        a img {
            height: auto;
            max-width: 600px;
            padding: 5px;
            transition: .2s ease-out;

            @media (max-width: 990px) {
                height: auto;
                width: 270px;
                padding: 5px;
            }
        }

        span.active~ul {
            @media (max-width: 990px) {
                margin-top: -15px;
                padding: 15px;
                height: 180%;
            }
        }

        span.active~ul li a {
            @media (max-width: 990px) {
                // opacity: 1;
                transition: .5s ease-in;
            }
        }

        span~ul li a {
            @media (max-width: 990px) {
                padding-top: 0px;
                // opacity: 0;
                transition: .2s ease-out;
            }
        }

        ul {

            display: flex;
            justify-self: center;
            color: white;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            height: 100%;


            @media (max-width: 990px) {
                position: absolute;
                flex-direction: column;
                align-items: center;
                min-width: 100%;
                left: 0;
                top: 62px;
                height: 0;
                background-color: white;
                transition: .5s ease;



            }

            &.active li a {
                color: black;
            }

            li {
                font-size: 1.4vw;
                list-style: none;
                padding: 0 15px 0 15px;

                .platform {
                    border: solid 1px white;
                    padding: 10px;
                    font-size: 0.8vw;

                }

                a {
                    text-decoration: none;
                    color: white;

                    @media (max-width: 990px) {
                        padding: 0px;
                        margin: 10px 0;
                        font-size: 1.3rem;
                        color: black;

                    }
                }
            }
        }
    }
}

.hover-underline-animation {
    display: inline-block;
    position: relative;

}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0;
    border-bottom: .5px solid white;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
}

.hover-underline-animation:hover:after {

    transform: scaleX(1);
    transform-origin: bottom left;
}

header nav ul.active li a.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: rgb(0, 0, 0);
}

header nav ul.active li a.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0;
    border-bottom: 1px solid black;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

header nav ul.active li a.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
