.dropdown {
    margin: 20px auto;
    position: relative;
    width: 700px;

    @media (max-width: 1367px) {}

    @media (max-width: 700px) {

        width: 90%;
    }

    .icon-btn {
        cursor: pointer;
    }

    .dropdown-btn {
        font-size: 1.125rem;
        padding: 15px;
        background: #fff;
        box-shadow: black 0px 2px 10px;
        font-weight: bold;
        border-radius: 5px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 2s;
        position: relative;
    }

    .dropdown-content {
        margin-top: 2px;
        font-size: 17px;
        padding: 15px;
        width: 100%;
        background: rgb(255, 255, 255);
        box-shadow: inset black 0px 2px 5px;
        font-weight: 500;
        color: black;


        @media (max-width: 725px) {

            width: fit-content;
        }

        .dropdown-item {
            padding: 15px;
            text-align: left;


            @media (max-width: 1367px) {}

            @media (max-width: 425px) {}

            ul li {

                list-style: none;
            }

            cursor: pointer;

            &:hover {
                background: #fefefe;
            }
        }
    }

}
