.plateform {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 110vh;

    background: url(../../assets/bg.jpg);
    background-size: cover;


    h1 {
        position: absolute;
        top: 100px;
        color: white;
    }
}
