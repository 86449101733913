@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");
header {
  position: fixed;
  background: transparent;
  width: 100%;
  padding: 25px;
  z-index: 5;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

header.active {
  background: white;
  padding: 10px;
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in;
}

header.active nav span i {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}

header.active nav a img {
  height: auto;
  max-width: 500px;
  padding: 5px;
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in;
}

@media (max-width: 920px) {
  header.active nav a img {
    height: auto;
    width: 200px;
    padding: 5px;
  }
}

header.active nav {
  height: 80px;
}

@media (max-width: 990px) {
  header.active nav {
    height: 40px;
  }
}

@media (max-width: 990px) {
  header {
    padding: 5px 20px;
  }
}

header nav {
  height: 100px;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 990px) {
  header nav {
    height: 65px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

header nav span {
  position: absolute;
  display: block;
  right: 30px;
  top: 20px;
  height: 20px;
  width: 20px;
  color: white;
}

header nav span.active i {
  content: url(../../assets/cross.svg);
  height: 100%;
  width: 100%;
}

@media (min-width: 990px) {
  header nav span {
    display: none;
  }
}

header nav span i {
  content: url(../../assets/menu.svg);
  height: 100%;
  width: 100%;
}

header nav a img {
  height: auto;
  max-width: 600px;
  padding: 5px;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
}

@media (max-width: 990px) {
  header nav a img {
    height: auto;
    width: 270px;
    padding: 5px;
  }
}

@media (max-width: 990px) {
  header nav span.active ~ ul {
    margin-top: -15px;
    padding: 15px;
    height: 180%;
  }
}

@media (max-width: 990px) {
  header nav span.active ~ ul li a {
    -webkit-transition: .5s ease-in;
    transition: .5s ease-in;
  }
}

@media (max-width: 990px) {
  header nav span ~ ul li a {
    padding-top: 0px;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
  }
}

header nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: center;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  height: 100%;
}

@media (max-width: 990px) {
  header nav ul {
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 100%;
    left: 0;
    top: 62px;
    height: 0;
    background-color: white;
    -webkit-transition: .5s ease;
    transition: .5s ease;
  }
}

header nav ul.active li a {
  color: black;
}

header nav ul li {
  font-size: 1.4vw;
  list-style: none;
  padding: 0 15px 0 15px;
}

header nav ul li .platform {
  border: solid 1px white;
  padding: 10px;
  font-size: 0.8vw;
}

header nav ul li a {
  text-decoration: none;
  color: white;
}

@media (max-width: 990px) {
  header nav ul li a {
    padding: 0px;
    margin: 10px 0;
    font-size: 1.3rem;
    color: black;
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 0;
  border-bottom: .5px solid white;
  bottom: 0;
  left: 0;
  background-color: white;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.hover-underline-animation:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

header nav ul.active li a.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: black;
}

header nav ul.active li a.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 0;
  border-bottom: 1px solid black;
  bottom: 0;
  left: 0;
  background-color: black;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

header nav ul.active li a.hover-underline-animation:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}
