.faq {
  padding-top: 150px;
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 90vh;
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  background: url(../../assets/lgbird.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 20%;
}

@media (max-width: 1024px) {
  .faq {
    min-height: 100%;
    background-size: 150% 150%;
    background-position: center;
  }
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .faq {
    background-size: 100% 100%;
    min-height: 1200px;
  }
}

@media (max-width: 425px) {
  .faq {
    background-size: 800%;
  }
}

.faq .faq-left {
  width: 50%;
}

@media (max-width: 1367px) {
  .faq .faq-left {
    display: none;
  }
}

.faq h3 {
  font-size: 30px;
  text-align: center;
  padding: 20px;
}

@media (max-width: 1367px) {
  .faq h3 {
    font-size: 20px;
  }
}

.faq .faq-right {
  width: 50%;
  margin: 50px 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1367px) {
  .faq .faq-right {
    margin: 0;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .faq .faq-right {
    margin: auto 40px;
  }
}

.faq .faq-right .faq-content {
  margin: 20px 0;
}

@media (max-width: 1367px) {
  .faq .faq-right .faq-content {
    text-align: center;
  }
}

.faq .faq-right .faq-content .search-bar {
  margin-top: -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

@media (max-width: 1367px) {
  .faq .faq-right .faq-content .search-bar {
    width: 90%;
    margin: -12px auto;
  }
}

.faq .faq-right .faq-content .search-bar input {
  width: 300px;
  height: 45px;
  padding: 18px;
  margin-right: -5px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  outline: none;
}

.faq .faq-right .faq-content .search-bar button {
  outline: none;
  color: white;
  padding: 10px;
  height: 47px;
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: #2596BE;
  cursor: pointer;
}

.faq .faq-right .faq-content .choice {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.faq .faq-right .faq-content .choice p {
  font-size: 25px;
  padding: 15px;
  color: rgba(255, 255, 255, 0.63);
}

.faq .faq-right .faq-content .choice p.active {
  color: white;
}

.faq .faq-right .questions {
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 1367px) {
  .faq .faq-right .questions {
    margin: 10px 0;
  }
}

.faq .faq-right h3 {
  color: white;
}

@media (min-width: 1024px) and (max-width: 1367px) {
  .faq .faq-right h3 {
    font-size: 38px;
  }
}
