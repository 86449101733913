.plateform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 110vh;
  background: url(../../assets/bg.jpg);
  background-size: cover;
}

.plateform h1 {
  position: absolute;
  top: 100px;
  color: white;
}
