.mentions {
  height: 90vh;
  background: url(../../assets/back2.jpg);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.mentions a {
  color: white;
}

.mentions .mentions-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 50%;
  text-align: center;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 15px;
}

@media (max-width: 1356px) {
  .mentions .mentions-container {
    width: 95%;
  }
}

.mentions .mentions-container h3 {
  font-size: 27px;
  font-weight: 500;
  padding-top: 20px;
}

.mentions .mentions-container h3:nth-child(1) {
  padding-top: 0;
  text-align: center;
  font-size: 33px;
}

.mentions .mentions-container .mentions-content {
  padding: 15px;
}

.mentions .mentions-container p {
  padding-top: 10px;
}
