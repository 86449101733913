.mentions {
    height: 90vh;
    background: url(../../assets/back2.jpg);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    a {
        color: white;
    }

    .mentions-container {
        height: fit-content;
        width: 50%;
        text-align: center;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.25);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        padding: 15px;

        @media (max-width: 1356px) {
            width: 95%;
        }

        h3 {
            font-size: 27px;
            font-weight: 500;
            padding-top: 20px;
        }

        h3:nth-child(1) {
            padding-top: 0;
            text-align: center;
            font-size: 33px;
        }

        .mentions-content {
            padding: 15px;
        }

        p {
            padding-top: 10px;

        }
    }

}
