.faq {
    padding-top: 150px;
    padding-bottom: 30px;
    display: flex;
    min-height: 90vh;
    width: 100%;
    max-height: fit-content;
    background: url(../../assets/lgbird.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% 20%;



    @media (max-width: 1024px) {
        min-height: 100%;

        background-size: 150% 150%;
        background-position: center;
    }

    @media (min-width:1024px) and (max-width: 1367px) {
        background-size: 100% 100%;
        min-height: 1200px;
    }

    @media (max-width:425px) {
        background-size: 800%;
    }

    .faq-left {
        width: 50%;

        @media (max-width: 1367px) {
            display: none;
        }
    }

    h3 {
        font-size: 30px;
        text-align: center;
        padding: 20px;

        @media (max-width: 1367px) {
            font-size: 20px;

        }
    }

    .faq-right {
        width: 50%;
        margin: 50px 20px;
        height: fit-content;
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(3px);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1367px) {
            margin: 0;
            width: 100%;
        }

        @media (min-width:1024px) and (max-width: 1367px) {

            margin: auto 40px;
        }

        .faq-content {
            margin: 20px 0;

            @media (max-width: 1367px) {
                text-align: center;
            }

            .search-bar {
                margin-top: -12px;
                display: flex;
                justify-content: center;
                align-items: center;
                filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

                @media (max-width: 1367px) {
                    width: 90%;
                    margin: -12px auto;
                }

                input {
                    width: 300px;
                    height: 45px;
                    padding: 18px;
                    margin-right: -5px;
                    border: 0.5px solid rgba(0, 0, 0, 0.5);
                    border-radius: 20px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    font-size: 18px;
                    outline: none;
                }

                button {

                    outline: none;
                    color: white;
                    padding: 10px;
                    height: 47px;
                    border: none;
                    border-radius: 0 20px 20px 0;
                    background-color: #2596BE;
                    cursor: pointer;
                }

            }

            .choice {
                color: white;
                display: flex;
                justify-content: center;

                p {
                    font-size: 25px;
                    padding: 15px;
                    color: rgba(255, 255, 255, 0.63);

                    &.active {
                        color: white;
                    }
                }

            }
        }

        .questions {
            width: 100%;
            margin: 20px 0;

            @media (max-width: 1367px) {
                margin: 10px 0;
            }
        }

        h3 {
            color: white;

            @media (min-width:1024px) and (max-width: 1367px) {

                font-size: 38px;
            }
        }
    }


}
