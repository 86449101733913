 .contact-page {
     display: flex;
     padding: 155px 0 35px 0;
     background-image: url(../../assets/lgpaper.webp);
     background-repeat: no-repeat;
     background-size: 115% 110%;
     background-position: 25% 20%;

     color: white;

     @media (max-width:425px) {
         background-size: 600%;
         background-repeat: no-repeat;
         background-position: top right;
     }

     @media (min-width:1024px) and (max-width: 1367px) {

         min-height: 1200px;
     }

     input {
         outline: none;
     }

     .contact-right {


         width: 52%;

         @media (max-width: 1367px) {

             display: none;
         }
     }

     #contact {
         padding: 50px;
         width: 50%;
         margin: 50px 10px;
         height: fit-content;
         background: rgba(0, 0, 0, 0.25);
         backdrop-filter: blur(3px);
         border-radius: 12px;

         h3 {
             text-align: center;
             font-size: 33px;
         }

         hr {
             margin: 5px auto 15px;
             width: 50%;
         }

         @media (max-width: 1367px) {
             margin: 0;
             padding: 10px;
             width: 100%;
         }

         @media (min-width:1024px) and (max-width: 1367px) {

             margin: auto 40px;
         }

         .form-contact-content {
             width: 100%;

             form {
                 display: flex;
                 flex-direction: column;

                 .identifiant {
                     display: flex;

                     @media (max-width: 748px) {
                         flex-direction: column;
                     }

                     .form-text {
                         padding: 5px;
                         width: 50%;
                         display: flex;
                         flex-direction: column;

                         @media (max-width: 748px) {
                             width: 100%;
                         }
                     }
                 }

                 .coordonees {
                     display: flex;

                     @media (max-width: 748px) {
                         flex-direction: column;
                     }

                     .form-text {
                         padding: 5px;
                         width: 50%;
                         display: flex;
                         flex-direction: column;

                         @media (max-width: 748px) {
                             width: 100%;
                         }
                     }
                 }

                 .message {
                     display: flex;
                     font-size: 25px;

                     .form-text {
                         padding: 5px;
                         width: 100%;
                         display: flex;
                         flex-direction: column;
                     }
                 }

                 label {
                     font-weight: 600;
                     font-size: 22px;
                     margin: 5px 0;

                     @media (max-width: 748px) {
                         font-size: 22px;
                     }
                 }

                 input {
                     padding: 10px;
                     font-size: 20px;
                 }

                 textarea {
                     font-size: 20px;
                     padding: 10px;
                     outline: none;
                 }

                 .g-recaptcha {
                     margin-left: 5px;
                     align-self: right;
                 }

                 button {
                     margin-top: 25px;
                     align-self: center;

                     background-color: #09A4E7;
                     padding: 5px;
                     color: white;
                     font-size: 22px;
                     border-radius: 5px;
                     width: 100%;
                     border: none;
                     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

                     @media (min-width:1024px) and (max-width: 1367px) {
                         align-self: flex-end;
                         width: 150px;
                     }
                 }
             }
         }
     }
 }
