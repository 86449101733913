.dropdown {
  margin: 20px auto;
  position: relative;
  width: 700px;
}

@media (max-width: 700px) {
  .dropdown {
    width: 90%;
  }
}

.dropdown .icon-btn {
  cursor: pointer;
}

.dropdown .dropdown-btn {
  font-size: 1.125rem;
  padding: 15px;
  background: #fff;
  -webkit-box-shadow: black 0px 2px 10px;
          box-shadow: black 0px 2px 10px;
  font-weight: bold;
  border-radius: 5px;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.dropdown .dropdown-content {
  margin-top: 2px;
  font-size: 17px;
  padding: 15px;
  width: 100%;
  background: white;
  -webkit-box-shadow: inset black 0px 2px 5px;
          box-shadow: inset black 0px 2px 5px;
  font-weight: 500;
  color: black;
}

@media (max-width: 725px) {
  .dropdown .dropdown-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.dropdown .dropdown-content .dropdown-item {
  padding: 15px;
  text-align: left;
  cursor: pointer;
}

.dropdown .dropdown-content .dropdown-item ul li {
  list-style: none;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #fefefe;
}
